<template>
  <div class="cancel-reason">
    <label class="cancel-reason__label">退会理由をご記入ください<span class="cancel-reason__label__required">*</span></label>
    <textarea class="cancel-reason__textarea" placeholder="退会理由を記載する" v-model="reason" type="text" @input="emitData('reason', reason)" />
  </div>
</template>

<script>

export default {
  data () {
    return {
      // 退会理由
      reason: ''
    }
  },
  methods: {
    /**
     * 親コンポーネントへデータを渡す
     * @param {String} key
     * @param {String} value
     */
    emitData (key, value) {
      this.$emit('set-postparams', key, value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";

.cancel-reason {
  &__label {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    &__required {
      color: $red_color;
    }
  }
  &__textarea {
    display: block;
    width: 100%;
    height: 110px;
    padding: 5px 0;
    margin-top: 5px;
    overflow: scroll;
    font-size: 1.6rem;
    line-height: 2rem;
    resize: none;
    border-bottom: 1px solid $black_lighten_color;
    outline: none;
    &::placeholder {
      color: $gray_color;
    }
  }
}
</style>
