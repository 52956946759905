<template>
  <div class="cancel" v-if="!isProcessing">
    <reason-form @set-postparams="setPostParams" />
    <warning class="cancel__warning" @set-check-box="setCheckBox" />
    <v-btn class="cancel__btn" depressed :disabled="!isFilled" @click="cancel()">退会する</v-btn>
  </div>
</template>
<script>
import ReasonForm from '@/components/cancel/reason.vue'
import Warning from '@/components/cancel/warning.vue'

export default {
  name: 'cancel',
  components: { ReasonForm, Warning },
  data () {
    return {
      postParams: {
        // 退会理由
        reason: ''
      },
      // 承諾済みかどうか
      isCheck: false
    }
  },
  async mounted () {
    if (!this.exemption) {
      if (!this.subscription && !this.subscriptionSchedule) await this.$store.dispatch('subscriptionSchedule/getSubscriptionSchedule', this.uid)

      // 通常ユーザーでサブスクリプションとサブスクリプションスケジュールどちらも存在しない場合は処理中なのでホームに遷移
      if (!this.subscription && !this.subscriptionSchedule) {
        this.$store.commit('setTelop', { show: true, msg: '現在、処理中です。\n時間をおいてから実行してください。', type: 'warning' })
        this.$router.push({ name: 'home' })
        return
      }
    }

    const promises = []

    // 限定グループ名を取得していない場合、取得する
    if (!this.groupName) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupName'))

    // Discord連携設定していない判定のときは最新の情報を取得する
    if (!this.isDiscordLinkage) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'discordServerID'))

    await Promise.all(promises)
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} 例外ユーザーかどうか
     */
    exemption () {
      return this.$store.getters['exemptions/exemption']
    },
    /**
     * @return {Object} サブスクリプション情報
     */
    subscription () {
      return this.$store.getters['subscriptions/subscription']
    },
    /**
     * @return {Object} サブスクリプションスケジュール
     */
    subscriptionSchedule () {
      return this.$store.getters['subscriptionSchedule/subscriptionSchedule']
    },
    /**
     * @return {String} 限定グループ名
     */
    groupName () {
      return this.$store.getters['settingGroup/groupName']
    },
    /**
     * @return {Boolean} Discord連携しているかどうか
     */
    isDiscordLinkage () {
      return this.$store.getters['settingGroup/discordLinkage']
    },
    /**
     * @return {Boolean} 退会理由が入力された上で、承諾済みかどうか
     */
    isFilled () {
      return this.postParams.reason.trim().length > 0 && this.isCheck
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters['isProcessing']
    }
  },
  methods: {
    /**
     * 退会処理
     */
    async cancel () {
      this.$store.commit('setSubmitting', true)

      // stripeの顧客情報の削除
      await this.$store.dispatch('functions/deleteCustomer')

      const promises = []

      // stripeの顧客情報削除が成功したら退会に伴うfirestore情報の更新
      promises.push(this.$store.dispatch('users/updateUser', { uid: this.uid, params: { isDeleted: true, updatedAt: new Date() } }))
      promises.push(this.$store.dispatch('payments/updatePayment', { uid: this.uid, params: { status: 'deletingCustomer', updatedAt: new Date() } }))
      promises.push(this.$store.dispatch('cancels/setCancel', { uid: this.uid, params: { reason: this.postParams.reason, createdAt: new Date() } }))

      await Promise.all(promises)

      // 退会メールの送信
      this.$store.dispatch('functions/sendEventEmail', 'canceledUser')

      // FBグループ未加入であればここで例外情報を削除する
      // 管理者側でisFacebookMemberが変更されている可能性があるのでusers情報の最新状態を取得する
      await this.$store.dispatch('users/getUser', this.uid)
      if (!this.user.isFacebookMember) {
        if (this.exemption) this.$store.dispatch('exemptions/deleteExemption', this.exemption.eid )
      }

      // auth情報の削除はcancels作成をトリガーとしてfunctionsで行うのでここではログアウトさせる。
      await this.$store.dispatch('auth/signout')

      this.$store.commit('setTelop', { show: true, msg: '退会を受け付けました。', type: 'success' })
      this.$router.push({ name: 'top' })
    },
    /**
     * ポストパラメータを取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {String} value 設定するポストパラメータのバリュー値
     */
    setPostParams (key, value) {
      this.postParams[key] = value
    },
    /**
     * チェックボックスを取得
     * @param {Boolean} value 承諾済みかどうか
     */
    setCheckBox (value) {
      this.isCheck = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.cancel {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__warning {
    margin-top: 40px;
  }
  &__btn {
    display: block;
    margin: 60px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 88px;
      height: 35px;
      padding: 0 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $concept_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $concept_transparent_color !important;
    }
  }
}
</style>
